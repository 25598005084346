<template>
  <div class="checkbox-block" @click="toggleChecked">
    <svg
      v-show="props.modelValue === true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M20 6 9 17l-5-5" />
    </svg>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";

type CheckboxProps = {
  modelValue: boolean;
};

const props = defineProps<CheckboxProps>();
const emit = defineEmits(["update:modelValue"]);

console.log("checked", props.modelValue);

const toggleChecked = () => {
  emit("update:modelValue", !props.modelValue);
  console.log("!modelValue", !props.modelValue);
};
</script>

<style scoped lang="scss">
.checkbox-block {
  width: 18px;
  height: 18px;
  border: 1px solid #d2d8e2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
