<template>
  <div class="step__block-field step__block-field--top">
    <div class="step__block-field-block">
      <h3>Overhead cost for:</h3>
      <span class="step__block-field-price"
        >{{ formatDimension(totalArea) }} sq. ft</span
      >
    </div>
  </div>
  <div class="step__block-table">
    <div class="step__block-item step__block-item--head">
      <div>
        <span>Name</span>
      </div>
      <div>
        <span>Unit</span>
      </div>
      <div>
        <span>{{ priceCode }}</span>
      </div>
      <div>
        <span>Sum</span>
      </div>
    </div>
    <template v-if="overheadCosts.length > 0">
      <div
        v-for="overheadItem of overheadCosts"
        :key="overheadItem.code"
        class="step__block-item"
      >
        <div>
          <span>{{
            getAdditionalCostItemTitle(overheadItem.costTypeCode)
          }}</span>
        </div>
        <div class="step__block-item-unit">
          <span>{{
            getAdditionalCostItemUnit(overheadItem.costTypeCode)
          }}</span>
        </div>
        <div>
          <span>{{
            formatMoney(
              getAdditionalCostItemPrice(
                overheadItem.costTypeCode,
                overheadItem.code,
              ),
            )
          }}</span>
        </div>
        <div>
          <span>{{
            formatMoney(
              getAdditionalCostItemSum(
                overheadItem.costTypeCode,
                overheadItem.code,
              ),
            )
          }}</span>
        </div>
      </div>
    </template>
  </div>
  <div class="step__block-field">
    <div class="step__block-field-block">
      <h3>page Total:</h3>
      <span class="step__block-field-price">{{
        formatMoney(pageCalculation?.[sumKey])
      }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useEstimateStore } from "../../stores/estimateStore";
// import {cloneDeep} from "loadsh";
import { computed, Ref, ref } from "vue";
import { CalculationItem } from "../../models/calculate";
import {
  getFromDictByCode,
  getTitleFromDictByCode,
  useDictStore,
} from "../../stores/dictStore";
import { AdditionalCostItem } from "../../models/structure";
import {
  CalculateService,
  OverheadCalculatorBuilder,
} from "../../services/calculateService";
import { formatDimension, formatMoney } from "../../utils/common";
import { EstimateService } from "../../services/estimateService";
import { usePriceStore } from "../../stores/priceStore";
import { EstimateInfo } from "resources/js/models/estimate";

defineExpose({ validatePage, savePage, reloadPage });

let sumKey = usePriceStore().data.currentSumKey;
let priceCode = usePriceStore().data.currentPriceKey;

const estimateStore = useEstimateStore();

const pageCalculation: Ref<CalculationItem> = ref(null);

/** @var totalArea общая площадь всех partition */
const totalArea = ref(
  EstimateService.calculateEstimatePartitionsArea(estimateStore.estimate),
);

const overheadCosts: Ref<AdditionalCostItem[]> = ref(null);
const info: EstimateInfo = estimateStore.estimate.info;
const installedSystem = computed(() => info.installedSystemCode);

//dicts
const dictStore = useDictStore();
const additionalCostTypes = dictStore.getAdditionalCosts("overhead");

reloadPage();

function savePage() {
  estimateStore.updateOverheadCosts(overheadCosts.value);
}

function validatePage() {
  return true;
}

function reloadPage() {
  totalArea.value = EstimateService.calculateEstimatePartitionsArea(
    estimateStore.estimate,
  );
  overheadCosts.value = EstimateService.overheadCostsFactory(
    totalArea.value,
    estimateStore.estimate.info.installation === "true",
    estimateStore.estimate.info.installation === "true",
  );
  pageCalculation.value = OverheadCalculatorBuilder.fromOverheadItem(
    overheadCosts.value,
    installedSystem.value,
  );
  CalculateService.deepCalculateSubitems(pageCalculation.value);
  CalculateService.deepCalculateSubItemsB2C(pageCalculation.value);
  CalculateService.deepCalculateSubItemsB2B(pageCalculation.value);
}

function getAdditionalCostItemTitle(additionalCostTypeCode: string): string {
  return getFromDictByCode(additionalCostTypes, additionalCostTypeCode)?.title;
}

function getAdditionalCostItemUnit(additionalCostTypeCode: string): string {
  return getFromDictByCode(additionalCostTypes, additionalCostTypeCode)?.unit;
}

function getAdditionalCostItemPrice(
  additionalCostTypeCode: string,
  elementCode: string,
) {
  /**
   *
   * CalculateService.getSubitemByCode возвращет объект вида:
   {
      elementCode: item.code,
      elementType: 'overhead',
      params: [item.costTypeCode, item.costValueCode],
      price: dictStore.findAdditionalCostPrice(item.costTypeCode, item.costValueCode)?.price,
      priceB2C: dictStore.findAdditionalCostPrice(item.costTypeCode, item.costValueCode)?.priceB2C,
      priceB2B: dictStore.findAdditionalCostPrice(item.costTypeCode, item.costValueCode)?.priceB2B,
      qty: item.qty,
    }

    После чего по priceCode находит цены. priceCode - price | priceB2C | priceB2B
   * */
  return CalculateService.getSubitemByCode(
    pageCalculation.value,
    elementCode,
  )?.[priceCode];
}

function getAdditionalCostItemSum(
  additionalCostTypeCode: string,
  elementCode: string,
) {
  return CalculateService.getSubitemByCode(
    pageCalculation.value,
    elementCode,
  )?.[sumKey];
}
</script>

<style scoped>
.step__block-table {
  padding: 15px 0;
  display: grid;
  row-gap: 15px;
}

.step__block-item {
  display: grid;
  grid-template-columns: 1fr 8em repeat(2, minmax(0, 55px));
  column-gap: 30px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-item--head {
  font-weight: 600;
}

.step__block-item-unit span {
  padding-right: 10px;
}

.step__block-item div:first-child {
  align-items: flex-start;
}

.step__block-item div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.step__block-field {
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-field.step__block-field--top {
  padding-top: 0;
  border-top: none;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step__block-field--hardware .step__block-field-block {
  justify-content: flex-start;
  column-gap: 10px;
}
</style>
